<template>
  <div class="relative grid grid-cols-6 gap-x-4 lg:grid-cols-12 lg:gap-x-8">
    <div
      class="col-span-6 xl:col-span-7"
      :class="reverse ? 'lg:order-2 lg:col-start-7' : 'lg:order-1'"
    >
      <!-- Desktop Only -->
      <div class="relative hidden lg:block lg:h-full lg:min-h-[760px]">
        <FlowPreview
          v-for="(item, idx) in data"
          :data="item"
          :key="idx"
          :idx="idx"
          :isActive="activeIdx === idx"
          :isVisible="activeIdx === idx && activeVisible"
          class="absolute left-0 top-0 h-full w-full"
        />
      </div>
    </div>
    <div
      class="col-span-6 xl:col-span-5"
      :class="reverse ? 'lg:order-1' : 'lg:order-2 xl:col-start-8'"
    >
      <div class="flex h-full flex-col justify-end">
        <FlowItem
          v-for="(item, idx) in data"
          :data="item"
          :key="idx"
          :idx="idx"
          :active="activeIdx === idx"
          :isVisible="activeIdx === idx && activeVisible"
          @setActiveIdx="updateActive"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  data: {
    type: Array,
    default: () => []
  },
  reverse: {
    type: Boolean,
    default: false
  }
})

const activeIdx = ref(0)
const activeVisible = ref(false)

function updateActive(idx: number) {
  if (activeIdx.value === idx) return

  activeVisible.value = false
  activeIdx.value = idx

  useTimeoutFn(() => {
    activeVisible.value = true
  }, 300)
}

onMounted(() => {
  activeVisible.value = true
})
</script>
