<template>
  <Transition name="zoomTo">
    <div v-show="isActive" class="h-full overflow-hidden bg-white px-5 py-10 text-left lg:p-10">
      <Image
        v-if="data.media"
        loading="lazy"
        class="aspect-square max-h-[100%]"
        sizes="(min-width: 820px) 700px, calc(94.4vw - 55px)"
        :src="data.media.responsiveImage ? data.media.responsiveImage?.src : data.media.url"
        :srcset="data.media.responsiveImage ? data.media.responsiveImage?.webpSrcSet : undefined"
        :width="data.media.responsiveImage ? data.media.responsiveImage.width : data.media.width"
        :height="data.media.responsiveImage ? data.media.responsiveImage.height : data.media.height"
        :alt="data.media.alt ? data.media.alt : ''"
      />
      <component
        v-for="block in data.blocks"
        :is="resolveBlockComponent(block.__typename as Blocks)"
        :data="block"
        :key="block.__typename"
      />
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { Blocks } from '~/types/components'

const { resolveBlockComponent } = useResolveComponent()

defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  idx: {
    type: Number,
    default: 0
  },
  isActive: {
    type: Boolean,
    default: false
  },
  isVisible: {
    type: Boolean,
    default: false
  }
})
</script>

<style lang="postcss">
.zoomTo-enter-active,
.zoomTo-leave-active {
  will-change: opacity, transform;
  opacity: 1;
  transition: opacity 800ms var(--ease-out-quart);
}

.zoomTo-enter-from,
.zoomTo-leave-to {
  opacity: 0;
}
</style>
