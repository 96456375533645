<template>
  <div class="container relative my-14 lg:my-24">
    <div v-if="data.tagline" class="relative mb-10 text-sm-semibold lg:mb-20 lg:text-xl-semibold">
      <span class="absolute top-[9px] left-0 h-0.5 w-full bg-gray1 lg:top-[13px]"></span>
      <span class="relative bg-white pr-4 lg:pr-16">{{ data.tagline }}</span>
    </div>
    <Heading :content="data.title" class="relative z-1 max-w-3xl lg:!mb-5" :arrow="arrowSVG" />
    <Flow :data="data.accordion" reverse class="mt-10 lg:mt-0" />
  </div>
</template>

<script setup lang="ts">
defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const arrowSVG = {
  class: '-right-80 top-0 hidden xl:block',
  html: `
  <svg width="303" height="193" viewBox="0 0 303 193" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M146.837 68.9148L147.428 69.7214L146.837 68.9148ZM293.747 193L302.115 185.043L291.04 181.775L293.747 193ZM1.29481 33.4997C35.6825 22.8905 69.5413 14.3157 99.0886 8.8065C128.659 3.29307 153.834 0.865143 170.888 2.50308C179.436 3.32404 185.79 5.15421 189.65 7.9962C191.56 9.40284 192.835 11.0397 193.491 12.9129C194.148 14.7877 194.213 16.9839 193.561 19.5568C192.244 24.7457 188.042 31.3207 180.285 39.4274C172.55 47.5115 161.368 57.0283 146.246 68.1081L147.428 69.7214C162.6 58.6049 173.883 49.0106 181.73 40.8101C189.555 32.6324 194.053 25.7496 195.499 20.0487C196.228 17.1768 196.191 14.5701 195.379 12.2518C194.566 9.93183 193.007 7.98521 190.835 6.38577C186.531 3.21557 179.73 1.34306 171.079 0.512236C153.736 -1.1534 128.336 1.31875 98.722 6.84038C69.0851 12.3663 35.1508 20.9615 0.705193 31.5886L1.29481 33.4997ZM146.246 68.1081C138.808 73.5585 132.998 78.2675 128.642 82.3391C124.296 86.4016 121.353 89.8713 119.698 92.8486C118.043 95.8252 117.594 98.4669 118.546 100.732C119.484 102.965 121.662 104.53 124.537 105.666C130.279 107.935 139.629 108.805 150.921 109.201C162.238 109.599 175.759 109.522 189.83 109.814C218.09 110.402 248.759 112.483 270.223 122.713C280.925 127.813 289.261 134.905 293.901 144.783C298.542 154.662 299.552 167.471 295.321 184.137L297.26 184.629C301.569 167.657 300.604 154.347 295.712 143.933C290.82 133.518 282.068 126.143 271.084 120.907C249.176 110.466 218.085 108.401 189.871 107.815C175.704 107.52 162.31 107.6 150.991 107.203C139.648 106.804 130.643 105.928 125.271 103.806C122.589 102.746 121.019 101.455 120.39 99.9576C119.775 98.4931 119.938 96.5318 121.446 93.8205C122.953 91.11 125.722 87.807 130.008 83.8001C134.284 79.8024 140.026 75.1454 147.428 69.7214L146.246 68.1081Z" fill="#080808"/>
  </svg>
  `
}
</script>
