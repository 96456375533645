<template>
  <div class="border-t-2 border-gray1 first:border-t-0">
    <button
      class="relative block w-full px-8 py-6 text-left text-lg-semibold lg:px-10 lg:py-8 lg:text-2xl-semibold"
      :class="{ 'bg-yellow cursor-default': isVisible }"
      @click="emit('setActiveIdx', idx)"
    >
      {{ data.title }}
    </button>
    <div :class="{ 'bg-yellow ': isVisible }">
      <Transition name="slideTab">
        <div v-show="isVisible" class="overflow-hidden">
          <div class="px-8 lg:px-10 lg:pb-6">
            <div v-html="data.html" class="mb-4 text-sm line-clamp-3 lg:text-base"></div>
            <Button
              v-if="Array.isArray(data.buttons) && data.buttons.length"
              :to="
                data.buttons[0].pageLink !== null
                  ? localePathByType(
                      data.buttons[0].pageLink.__typename as string,
                      data.buttons[0].pageLink.slug as string
                    )
                  : data.buttons[0].externalLink
              "
              :target="data.buttons[0].externalLink && '_blank'"
              isType="circle-secondary"
              :icon="{ name: 'arrow-right-long', color: 'black', size: 'circle' }"
              class="mb-4 border-black xl:!h-16 xl:!w-16"
              :title="$t('flow.more')"
            />
          </div>
          <!-- Mobile Only -->
          <div class="relative lg:hidden">
            <FlowPreview :data="data" :key="idx" :idx="idx" :isActive="true" :isVisible="true" />
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  idx: {
    type: Number,
    default: 0
  },
  isActive: {
    type: Boolean,
    default: false
  },
  isVisible: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits<{
  (event: 'setActiveIdx', num: number): void
}>()
</script>

<style lang="postcss">
.slideTab-enter-active,
.slideTab-leave-active {
  opacity: 1;
  transition:
    opacity 400ms var(--ease-out-sine) 200ms,
    max-height 600ms var(--ease-out-sine);
  max-height: 960px;

  @screen lg {
    max-height: 300px;
    transition:
      opacity 200ms var(--ease-out-sine) 200ms,
      max-height 300ms var(--ease-out-sine);
  }
}

.slideTab-enter-active {
  transition-delay: 100ms;
  transition-duration: 600ms;
}

.slideTab-enter-from,
.slideTab-leave-to {
  max-height: 0;
  opacity: 0;
}
</style>
